

/*
    Documentary page
*/

.embed-container {
    padding: 56.25% 0 0 0;
    position: relative;
    height: calc(99vh - 71px);
    max-height: 972px;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding-top: 75px;

    iframe {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        max-width: 1440px;
        max-height: calc(99vh - 71px);
    }

    @include media-breakpoint-down(md) {
        height: 577px;
    }
}
.hosting {
    @include media-breakpoint-down(md) {
            margin-top: 77px;
            margin-bottom: 51px;
        }
}
.screenings {
    margin: 161px 0;

    @include media-breakpoint-down(lg) {
        margin-bottom: 0px;
    }


    .col-lg-12 {
        max-width: 1920px;
        padding-left: 7%;
        padding-right: 7%;
        @include media-breakpoint-down(lg) {
            max-width: unset;
        }
    }

    @include media-breakpoint-down(lg) {
        margin-top: 59px;
    }

    .screenings__heading {
            margin-bottom: 100px;
            padding-bottom: 70px;
            border-bottom: 1px solid $stormy-blue;
           @include media-breakpoint-down(lg) {
                padding-bottom: 0;
                border-bottom: none;
                text-align: center;
                margin-bottom: 37px;
            }
    }

    .screening__date {
       padding-bottom: 80px;

         @include media-breakpoint-down(lg) {

            &:first-of-type {
                border-top: 1px solid $stormy-blue;
            }
               border-bottom: 1px solid $stormy-blue;
               padding-top: 47px;
               padding-bottom: 40px;
            }

             &:last-of-type {
                border-bottom: none;
            }

        .date__date {

            text-align: left;

            @include media-breakpoint-down(lg) {
                margin-bottom: 30px;
                text-align: center;
            }

            .date__date--centered {

                text-align: center;
                max-width: 120px;
            
                @include media-breakpoint-down(lg) {
                    margin: 0 auto;
                }

            }



            .date__day {
                color: $twilight-blue;
                font-family: Europa, sans-serif;
                font-size: 86px;
                font-size: 8.6rem;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 0.84;

            }

            .date__month {

                color: $charcoal-grey;
                margin-top: 5px;
                font-size: 14px;
                font-size: 1.4rem;
            }
        }

        .date__location {

            @include media-breakpoint-down(lg) {
                text-align: center;
            }

            .date__name {
                color: $twilight-blue;
                font-family: Europa, sans-serif;
                font-size: 30px;
                font-size: 3.0rem;
                line-height: 1.17;
                margin-bottom: 10px;

                 @include media-breakpoint-down(lg) {
                    font-size: 22px;
                    margin-bottom: 30px;
                }
            }

            .date__address {
                margin-bottom: 21px;
            }

        }
    }
}

.where-we-been {
    background-color: $light-grey;
    padding: 80px 0 60px;
    text-align: center;
     @include media-breakpoint-down(md) {
        padding: 70px 0 50px;
    }

    p {
        max-width: 960px;
        margin: 0 auto;
        margin-top: 38px;
        margin-bottom: 45px;
    }
    .logo-carousel {
        margin-top: 70px;

        .logo-slider {
            .slick-slide img {
                margin: 0 auto;
            }

            .slick-prev:before, .slick-next:before {
                background-image: url(../theme-images/left.svg);
                background-size: 26px 26px;
                background-repeat: no-repeat;
                content: '';
                display: block;
                height: 26px;
                width: 26px;
            }

            .slick-next:before {
                background-image: url(../theme-images/right.svg);
            }
        }

      .slick-accessible .slick-dot button {
            border-radius: 50%;
            height: 10px;
            width: 10px;
      }

      .slick-dots {
        margin-top: 40px;
      }

      .slick-dots li.slick-active button:before {
            color: $stormy-blue;
      }

      .slick-accessible .slick-dot-content {
            background: transparent;
      }

      .slick-dots li button {
            font-size: 0rem;
      }

      .slick-accessible .slick-dot-content {
            font-size: 1.5rem;
      }
    }
}

.video-background {
    height: auto;
    overflow: hidden;
    position: relative;
    height: calc(100vh - 65px);
    max-height: 972px;
    width: 100%;

    @include media-breakpoint-down(md) {
        min-height: 577px;
        height: calc( 100vh - 72.25px) ;
    }

    .heading1, .heading3 {
        color: $white;
        text-align: center;
    }

    .heading1 {
        font-size: 54px;
        font-size: 5.4rem;
        line-height: 1em;
        margin-bottom: 20px;
        padding: 0 10px;

        @include media-breakpoint-down(md) {
            font-size: 32px;
            font-size: 3.2rem;
            margin-bottom: 0;
        }
    }

    .heading3 {
        font-size: 12px;
        font-size: 1.2rem;
        font-weight: normal;
        @include media-breakpoint-down(md) {
            font-size: 14px;
            font-size: 1.4rem;
        }
    }

    .video-background--intro {
        position: relative;
    }

    .video-background--videoWrapper {
        position: absolute;
        background-color: #000000;
        top: 0;
        height: 100%;
        width:100%;
        justify-content: center;
        align-items: center;
        display: none;

        @include media-breakpoint-down(md) {
            min-height: 577px;
        }

    }

    .video-background--video {
       display: flex;
       width: 100%;

        img {
            @include media-breakpoint-down(md) {
                width: 25px;
            }
        }

        .close {
            padding:10px;
            position: absolute;
            top: 20px;
            right: 25px;
            z-index: 99; 

            @include media-breakpoint-down(md) {
                padding: 6px;
            }
        }

    }

    .play-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 33%;
        justify-content: center;


        button {
            background: none;
            border: none;
            cursor: pointer;
            margin: 50px auto 0;
            transition: opacity 1s ease;

            &.play, &.pause.show {
                opacity: 1;
            }

            &.pause {
                opacity: 0;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .video-background--pause-button {
        background: transparent;
        border: 0;
        position: absolute;
        right:    32px;
        bottom:   32px;
    }

    @include media-breakpoint-down(md) {
        .play-container button {
            opacity: 1;
        }
    }
}


.video-play-button {
    // position: absolute;
    z-index: 10;
    // top: 50%;
    // left: 50%;
    // transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
    position: relative;
    z-index: 0;
    @include media-breakpoint-down(md) {
        width: 23px;
        height: 27px;
    }
}

.video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 115px;
    height: 115px;
    background: rgba($white, 0.27);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
    @include media-breakpoint-down(md) {
        width: 90px;
        height: 90px;
    }
}

.video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 115px;
    height: 115px;
    background: rgba($white, 0.27);
    border-radius: 50%;
    transition: all ease-in-out 200ms;
    @include media-breakpoint-down(md) {
        width: 90px;
        height: 90px;
    }
}

.video-play-button {
    &:hover, &:active, &:focus {
        &:after {
            background-color: $twilight-blue;
            transition: all ease-in-out .2s;
        }
    }
}

.video-play-button img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
}

.video-play-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 30px solid $white;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    @include media-breakpoint-down(md) {
        border-left: 23px solid $white;
        border-top: 13.5px solid transparent;
        border-bottom: 13.5px solid transparent;
    }
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

