.events-page { 
    .link {
        margin-right: 2.4rem 
    }

   

    .row {
        @include media-breakpoint-down(md) {
            margin-left: unset;
            margin-right: unset;
        }
    }

    .link-columns { 
        max-width: 1600px;


        .link-column {
            padding-left: unset;
            padding-right: unset;

            @include media-breakpoint-up(lg) {
                padding-left: 50px;
                padding-right: 50px;
            }
            .image-container {

                .link-column__image {
                    margin-bottom: 25px;

                    @include media-breakpoint-up(md) {
                        margin-bottom: 50px;
                    }
                }
                .image__caption {
                    width: unset;
                    max-width: unset;
                    right: unset;
                    max-width: 70%;
                    padding-right: 20px;
                    padding-bottom: 15px;
                    position: absolute;
                    top: unset;
                    bottom: 0;
                    margin: unset;
                }
            }
            .link-column__text {
                width: unset;

                @include media-breakpoint-down(md) {
                    margin: unset;
                }
            }

            
        }
    }

    h2.image-caption.image-caption.image-caption.image-caption { 
        color: #3a434f;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;

        @include media-breakpoint-up(md) {
            font-size: 1.8rem;
        }
    }

    .event-name {
        color: #074a76;
        font-family: Europa,serif;
        font-size: 30px;
        font-size: 3rem;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: 0;
        padding-top: 5px;
        text-transform: none;
        min-height: 35px;
        background-color: #fff;
        margin-top: -10px;
    }

    .event-description {
     
        margin-bottom: 30px;
        margin-top: 30px;
    }
}