.news {

	 @include media-breakpoint-up(md) {
	 	padding-bottom: 160px;
    max-width: 1920px;
    margin: 0 auto;
    padding-left: 8.33333%;
    padding-right: 8.33333%;
	 }

	 .col {
	 	padding-left: 0px;
	 	padding-right: 0px;
	 }
	.news-heading {
		font-family: Europa, sans-serif;
	  font-size: 32px;
	  font-size: 3.2rem;
	  font-weight: bold;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: normal;
	  letter-spacing: 2px;
	  color: #6c757d;
	  text-transform: uppercase;
	  margin-bottom: 80px;

	  @include media-breakpoint-down(lg) {
	  	font-size: 20px;
	  	font-size: 2.0rem;
	  }

	}
	h2.news-heading {
		margin-bottom: 33px;
	}

	.news-wrapper {
		@include media-breakpoint-down(sm) {
			padding-left:0px;
			padding-right: 0px;
		}
	}
	.articles {
		margin-right: 6vw;
		max-width: 835px;

		  @media only screen and (max-width: 1250px) {
		  	  flex-basis: auto;
		  	  max-width: unset;
		  	  margin-right: 0px;
		  }

		  @include media-breakpoint-down(md) {
		  	margin-top: 48px;
		  }
	}
	.article-img-container {

	}
	.article-img {
		height:206px;
		max-width:343px;
		background-repeat:no-repeat;
		background-size:cover;

		@include media-breakpoint-down(md) {
			max-width: 50vw;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
			height: 40vw;
			min-height: 234px;
			max-width: unset;
			background-position: center;
		}
	}
	.article-heading {
		font-size: 24px;
		font-size: 2.4rem;
		font-family: Europa, sans-serif;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.3;
	  letter-spacing: normal;
	  color: $twilight-blue;
	  margin-top: 24px;

	  &:hover, :focus {
	  	color: $twilight-blue;
	  }
	}

	.press-heading {
		font-family: Europa, sans-serif;
	  font-size: 22px;
	  font-size: 2.2rem;
	  font-weight: normal;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.27;
	  color: $twilight-blue;

	  margin-bottom: 16px;

	  @media only screen and (max-width: 1555px) {
	  	font-size: 20px;
	  	font-size: 2.0rem;
	  }

	  &:hover, :focus {
	  	color: $twilight-blue;
	  }
	}

	.date {
		font-family: Europa, sans-serif;
	  font-size: 16px;
	  font-size: 1.6rem;
	  font-weight: bold;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: normal;
	  letter-spacing: 1.78px;
	  color: $charcoal-grey;
	  text-transform: uppercase;

	  @media only screen and (max-width: 1555px) {
	  	font-size: 13px;
	  	font-size: 1.3rem;
	  }

	  @include media-breakpoint-down(sm) {
	  	margin-top: 32px;
	  }
	}

	.press {
		.news-wrapper {
			border-bottom: 1px solid $twilight-blue;
			padding-bottom: 32px;
			margin-bottom: 32px;
			&.last {
			border-bottom: none;
			margin-bottom: 0px;
			}
		}


		.date {
			margin-bottom: 24px;
		}

		max-width: 385px;

		   @media only screen and (max-width: 1250px) {
		  	  flex-basis: auto;
		  	  max-width: unset;
		  	  margin-top: 48px;
		  	  .news-heading {
			  		padding-top: 47.5px;
			  	}
		  }
		  @include media-breakpoint-down(sm) {
		  	margin-bottom: 40px;

		  }
	}
	.article {
		padding: 47px 0px 47px 0px;
		border-bottom: 1px solid $twilight-blue;
		
		&.last {
			border-bottom: none;
		}

		@media only screen and (max-width: 1250px) {
		  margin-left: 0px;
    	margin-right: 0px;
    }

		@include media-breakpoint-down(sm) {
			border-bottom: none;
			margin-left: -15px;
    	margin-right: -15px;
    	padding: 0px;
		}
	}

	.link {
		margin-top: 0px;
		margin-bottom: 0px;
			@include media-breakpoint-down(sm) {
				margin-top: 24px;
				margin-bottom: 49px;
			}
	}

	.press .link {
		@include media-breakpoint-down(sm) {
			margin-bottom: 65px;
			margin-top: 0px;
		}
	}

	.article-body {
		height: 206px;

		@include media-breakpoint-down(sm) {
	  	height: unset;
	  	padding-right: 0px;
	  }
	}

}
.page-numbers {
	font-family: Europa;
	font-size: 18px;
	font-size: 1.8rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 2px;
	color: #9ca1a7;
	margin-right: 16px;
	
	&.current {
	  	 color: $charcoal-grey;
	}
}
.stories {
	@include media-breakpoint-down(sm) {
		background-position: center center !important;
	}
	.pagination {
		position: relative;
		&::after {
			position: absolute;
			top: -1px;
			height: 2px;
			background-color: blue;
		}
	}
}

