.wpcf7 {
	max-width: unset;
	margin-top: 120px !important;
	color: #3a434f	;

	.wpcf7-form {

		input, select, textarea {
			border: unset;
			margin-top: 15px;
			padding-left: 0;
			font-size: 28px;
			height: auto;
		}

		textarea {
			font-size: 18px;
		}

		label {
			font-family: Europa, sans-serif;
		    font-weight: 700;
		    font-size: 16px;
		    text-transform: uppercase;
		    color: #3a434f;
		    letter-spacing: 3px;
		    margin-bottom: 40px;
		    position: relative;

		    &:after {
				content: "";
				width: 100%;
				height: 1px;
				background-color: #3a434f;
				position: absolute;
				bottom: -10px;
			}
		}

		.wpcf7-submit {
			border: 1px solid $twilight-blue;
			border-radius: 0px;
			text-transform: uppercase;
			font-family: Europa, sans-serif;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.69;
			letter-spacing: 3px;
			color: #074a76;
			width: 200px;
			margin: 0 auto;
			display: block;
			margin-top: 50px;
			padding-left: .75rem;
			position: relative;

			&:hover, &:focus, &:active {
				background-color: unset !important;
				color: #074a76 !important;
				border-color: transparent;
    			transition: border-color linear;
    			&:after {
			        border-width:1px;
			        animation:aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
			        animation-delay:0s, 0.25s, 0.5s;
			    }
			    &:before {
			        border-width:1px;
			        animation:nW_nH 0.5s, aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
			        animation-delay:0s, 0.5s, 0.75s, 1s;
			    }
			}
			&:after {
				content: ' ';
				position:absolute;
				top:0px;
				left:0px;
				width:0%;
				height:0%;
				z-index:1;
				border-top:1px solid $twilight-blue;
				border-right:1px solid $twilight-blue;
				border-width:0px;
			}
			&:before {
				content: ' ';
				position:absolute;
				bottom:0px;
				right:0px;
				width:0%;
				height:0%;
				z-index:1;
				border-bottom:1px solid $twilight-blue;
				border-left:1px solid $twilight-blue;
				border-width:0px;
			}
			@include media-breakpoint-down(lg) {
				font-size: 10px;
				font-size: 1.0rem;
				line-height: 2.7px;
				letter-spacing: 1.6px;
				padding: 10px 7.5px 7.5px;
			}
		}
	}
}

// FOOTER SUBSCRIBE FORM CONSTANT CONTACT
div.ctct-form-embed div.ctct-form-defaults{
	background-color: unset !important;
	width: max-content !important;
	padding: 0 !important;
	border-radius: 0 !important;
	margin-top: 3rem;

	h2, p, label, label:before, a, div {
		color: white !important;
		font-family: Europa, sans-serif !important;
		font-weight: 700 !important;
		font-size: 14px !important;
		text-transform: uppercase;
		letter-spacing: 1.5px;
	}
	label {
		font-size: 10px !important;
	}
	p {
		display: none !important;
	}
	h2 {
		font-size: 15px !important;
	}

	input {
	border: unset !important;
	background-color: unset !important;
	border-radius: 0px !important;
	border-bottom: 1px solid white !important;
	color: white !important;
	font-family: Europa, sans-serif !important;
	font-weight: 500;
	font-size: 14px !important;
	letter-spacing: 1.5px;
	width: 100% !important;
	box-shadow: unset !important;
	padding: .5rem 0 !important;
	height: auto !important;
	}

	button {
	border: 1px solid white !important;
	background-color: unset !important;
	border-radius: 0px !important;
	color: white !important;
	cursor: pointer !important;
	font-family: Europa, sans-serif;
	font-size: 14px !important;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: 1.5px;
	line-height: 1.69 !important;
	margin-top: 1.5rem;
	text-transform: uppercase !important;
	width: 100% !important;
	padding: .5rem 0 !important;

	&:hover, &:focus, &:active {
		background-color: white !important;
		color: #074a76 !important;
		border-color: transparent;
		transition: border-color linear;
		&:after {
			border-width:1px;
			animation:aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
			animation-delay:0s, 0.25s, 0.5s;
		}
		&:before {
			border-width:1px;
			animation:nW_nH 0.5s, aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
			animation-delay:0s, 0.5s, 0.75s, 1s;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		div, h2, p, a {
			font-size: 12px !important;
		}
		button {
			margin-top: 1rem;
		}
	}
}

// .footer-form {
// 	color: white;
// 	font-family: Europa, sans-serif;
// 	font-weight: 700;
// 	font-size: 14px;
// 	text-transform: uppercase;
// 	letter-spacing: 1.5px;
// 	margin-top: 3rem;
// 	width: max-content;

// 	div {
// 		overflow-wrap: break-word;
// 		max-width: 275px;
// 	}

// 	input {
// 		border: unset;
// 		background-color: unset;
// 		border-bottom: 1px solid white;
// 		color: white;
// 		font-family: Europa, sans-serif;
// 		font-weight: 500;
// 		font-size: 14px;
// 		letter-spacing: 1.5px;
// 		margin-top: 1rem;
// 		width: 100%;
// 	}

// 	button {
// 		border: 1px solid white;
// 		background-color: unset;
// 		border-radius: 0px;
// 		color: white;
// 		cursor: pointer;
// 		font-family: Europa, sans-serif;
// 		font-size: 14px;
// 		font-weight: bold;
// 		font-style: normal;
// 		font-stretch: normal;
// 		letter-spacing: 1.5px;
// 		line-height: 1.69;
// 		margin-top: 1.5rem;
// 		text-transform: uppercase;
// 		width: 100%;

// 		&:hover, &:focus, &:active {
// 			background-color: white !important;
// 			color: #074a76 !important;
// 			border-color: transparent;
// 			transition: border-color linear;
// 			&:after {
// 				border-width:1px;
// 				animation:aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
// 				animation-delay:0s, 0.25s, 0.5s;
// 			}
// 			&:before {
// 				border-width:1px;
// 				animation:nW_nH 0.5s, aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
// 				animation-delay:0s, 0.5s, 0.75s, 1s;
// 			}
// 		}
// 	}
// 	@include media-breakpoint-down(xs) {
// 		div, input, button {
// 			font-size: 12px;
// 		}
// 		div {
// 			max-width: 240px;
// 		}
// 	}
// }