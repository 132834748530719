@charset "UTF-8";

// Settings
// ==================================================
$hamburger-padding-x           : 10px !default;
$hamburger-padding-y           : 10px !default;
$hamburger-layer-width         : 30px !default;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 4px !default;
$hamburger-layer-color         : #000 !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : #074a76 !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  squeeze
) !default;

// Base Hamburger (We need this)
// ==================================================
@import "base";

// Hamburger types
// ==================================================

@import "types/squeeze";


// ==================================================

