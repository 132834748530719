

/*
    Scholarships page
*/

.page-template-scholarships {
    .image-callout {
        margin-bottom: 0;
    }

    .image-callout__image--banner {
        min-height: 96vh;
        @include media-breakpoint-down(md) {
            min-height: auto;
        }
    }
}

.scholarship-announcement {

    hr.shortline {
        border-top: 1px solid #4e87a0; width: 160px; margin-top:88px;padding-bottom:55px;
    }
    @include media-breakpoint-down(md) {
        margin-top: 69px;
        hr.shortline {
            width: 72px;
            float: left;
            padding-left: 7%;
        }
         .content {
    
                padding-left: 7%;
                padding-right: 7%;
            }
    }

}
.scholarship-list {
    margin-bottom:170px;


    @include media-breakpoint-down(md) {
        margin-bottom: 0px;
    }
    .scholarship {
        margin-top: 80px;

         @include media-breakpoint-down(md) {
                margin-top: 37px!important;

            }

        .scholarship__heading {
            border-bottom: 1px solid $stormy-blue;

            @include media-breakpoint-down(md) {
                border: none;
                padding-left: 7%;
                padding-right: 7%;
            }
        }

        .scholarship__description {
            border-bottom: 1px solid $stormy-blue;
            padding-top: 8px;
            padding-bottom: 70px;
            padding-left: 0px;
            padding-right: 0px;


            @include media-breakpoint-down(md) {
                padding-left: 7%;
                padding-right: 7%;
            }

            ul {
                margin-top: 15px;
                margin-left: 50px;

                li {
                    font-size: 18px;
                    font-size: 1.8rem;
                    font-family: Europa, sans-serif;
                    line-height: 1.5;
                    list-style: initial;
                    margin-top: 10px;
                    padding-left: 20px;
                    text-transform: initial;
                }
            }
        }

        .scholarship__link {
            margin-top: 20px;
        }
    }
}

.scholars {
    padding-bottom: 60px;
    max-width: 1920px;
    padding-left: 7%;
    padding-right: 7%;

    @include media-breakpoint-down (md) {
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .row {
        max-width: 100vw;
        @include media-breakpoint-down (md) {
            max-width: none;
            min-width: 100vw;
        }
    }

    .scholars__boxes {
        margin-bottom: 60px;


        @include media-breakpoint-down (md) {
            padding-left: 0px;
            padding-right: 0px;
            margin-bottom: 26px;
        }


            .scholar__image {
                position: relative;
                background-size: cover;
                min-height: 300px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-bottom: 30px;
                    background-position: center 16%;
                }

                .image__caption {
                    background-color: $white;
                    padding: 15px 0px 0px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    max-width: 384px;
                    width: 80%;
                    background-size: cover;
                    background-position: top center;
                    max-height: 300px;

                    @include media-breakpoint-down(md) {
                        position: relative;
                        top: 270px;
                        left: 0;
                        right: 0px;
                        padding: 20px 28px 20px 28px;
                        margin: 0 auto;
                        width: 83.33333%;
                        max-width: unset;
                    }

                    p {
                        font-size: 14px;
                        font-size: 1.4rem;
                        font-weight: bold;
                        letter-spacing: 1.6px;
                        margin-bottom: -5px;
                        text-transform: uppercase;
                        color: $twilight-blue;
                    }
                }
            }

            .scholar__description {
                margin-top: 24px;
                max-width: 480px;

                @include media-breakpoint-down(md) {
                padding: 0px 28px 20px 28px;
                margin: 0 auto;
                width: 83.33333%;
                max-width: unset;

            }

            p {
                
                font-family: Europa, sans-serif;
                font-size: 18px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;


                @include media-breakpoint-down(md) {
                    font-size: 15px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.47;
                    letter-spacing: normal;
                }
            }
        }
    }
    
}

.middle-callout {
    .image__caption {
        left: 0;
        max-width: 50%;
        text-align: center !important;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.skip {
    padding-top: 150px;
    @include media-breakpoint-down(md) {
        padding-top: 0;
    }
}