

/*
    Get involved page
*/

.page-template-get-involved {
    .intro__halfbleed.image-callout {
        margin-bottom: 0px;
    }
}

.involved-volunteer {
    padding-bottom: 0px;

    h2 {
        max-width: 300px;
    }

    @include media-breakpoint-down(md) {
            h2 {
                margin-bottom: 24px;
                max-width: unset;
            }
        }

    .v-intro p {
        max-width: 800px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 11px;
        padding-bottom: 60px;
        margin-top: 37px;
        .offset-1 {
                padding-left: 7%;
                padding-right: 7%;
            }
    }

    .involved-volunteer__columns {
        margin-top: 80px;

        p {
            max-width: 378px;
        }
        @include media-breakpoint-down(md) {
            margin-top: 40px;

            .offset-1 {
                padding-left: 7%;
                padding-right: 7%;
            }

            p {
                max-width: unset;
            }
        }

        .involved-volunteer__column {
            margin-bottom: 70px;

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }
    }

    .heading3 {
        color: $charcoal-grey;
        font-size: 18px;
        font-size: 1.8rem;
        text-transform: uppercase;

         @include media-breakpoint-down(md) {
            font-size: 14px;
            font-size: 1.4rem;
         }
    }

}

.page-template-get-involved {
    .middle-callout {
        &.mt-200 {
            margin-top: 100px;
            @include media-breakpoint-down(md) {
                margin-top: 0;
            }
        }
        .image-callout__image {
            @include media-breakpoint-down(md) {
                background-position: center top;
            }
        }
    }
}