// ==========================================================================
// General Animation classes
// ==========================================================================
.slide-up {
  animation-name: slide-up;
  animation-duration: 1s;
  animation-delay: 200ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
}
.animation {
  opacity: 0;
 
}
.logo-slide-up {
  animation-name: logo-slide-up;
  animation-duration: 1s;
  animation-delay: 200ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.fade-in {
  animation-name: fade-in;
  animation-duration: 1s;
  animation-delay: 200ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.slide-left {
  animation-name: slide-left;
  animation-duration: 1.25s;
  animation-delay: 200ms;
  animation-interation-count: 1;
  animation-fill-mode: forwards;
}
.slide-right {
  animation-name: slide-right;
  animation-duration: 1.25s;
  animation-delay: 200ms;
  animation-interation-count: 1;
  animation-fill-mode: forwards;
}

//Image Callout
.animate-slide-left {
  animation-name: slide-left;
  animation-duration: 1.25s;
  animation-interation-count: 1;
  animation-fill-mode: forwards;
  right: -25%;
  } 

.animate-slide-right {
    animation-name: slide-right;
    animation-duration: 1.25s;
    animation-interation-count: 1;
    animation-fill-mode: forwards;
}

@media (max-width: 991.98px) {
  .animate-slide-left {
    right: 0;
  }
}     

// ==========================================================================
// Specific animation adjustments
// ==========================================================================
.blockquote__quote {
  padding: 0;
}

.blockquote__link {
  animation-delay: 100ms;
  padding: 0;
}

// ==========================================================================
// Keyframes
// ==========================================================================
@keyframes fade-in {
  from {
    opacity: 0;

  }

  to {
    opacity: 1;
 
  }
}

 @keyframes slide-left {
  from {
    opacity: 0;  
    transform: translateX(800px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right {
  from {
    opacity: 0;
    transform: translateX(-800px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 0;  
    transform: translateY(50px);
  }

  to {
    opacity: 1;  
    transform: translateY(0);
  }
}

@keyframes logo-slide-up {
  from {
    opacity: 0;
   
    margin-top: 150px;  
    transform: scale(1.5);
  }

  to {
    opacity: 1;

    margin-top: 0px;
    transform: scale(1);
  }
}