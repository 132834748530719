//    Image callout

.image-callout {
    margin-bottom: 200px;

     @include media-breakpoint-down(lg) {
        height: 100%;
        max-height: unset;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 26px;
    }

    .image-callout__image {
        background-size: cover;
        background-repeat: no-repeat;
        height: 47vw; max-height: 900px;
        // min-height: calc(100vh - 65px);
        background-position: top center;

        @include media-breakpoint-down(md) {
            padding-right: 0;
        }

        .image-callout__wrapper {
            border-left: 140px solid;
            border-image: linear-gradient(to top, $twilight-blue 80%, $white 20%) 1 100%;

            @include media-breakpoint-down(md) {
                border-left: 40px solid;
            }
        }

        .image__caption {
            background-color: $white;
            padding: 26px 16px 26px 0px;
            position: absolute;
            right: 0;
            bottom: 0;
            text-align: right;
            width: 622px;

            @include media-breakpoint-down(md) {
                bottom: -1px;
            }//media down md

            p {
                margin-bottom: 0;
                text-transform: uppercase;
            }//p
        }//image__caption

        &.order-2 {
            padding-right: 0;
            text-align: right;

            @include media-breakpoint-down(md) {
                padding-left: 0;
            }

            .image-callout__wrapper {
                border-left: none;
                border-right: 140px solid;

                @include media-breakpoint-down(md) {
                    border-right: 40px solid;
                }
            }

            .image__caption {
                right: unset;
                text-align: left;
            }
        }//&.order-2
    }//image-callout__image

    .image-callout__heading {
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            font-size: 32px;
            font-size: 3.2rem;
        }
    }

    .image-callout__link {
        margin-top: 40px;

        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
        }

        .link:hover ~ .link--icon {
            .play-icon {
                width: 32px;
                height: 32px;
                transition: all 0.1s linear;
            }
        }

        .link--icon {
            margin-left: 7.5px;
            padding-left: 5px;
            position: relative;
            top: -1px;

            .play-icon {
                transition: all 0.1s linear;
            }
           
            &:hover {
                .play-icon {
                    width: 32px;
                    height: 32px;
                    transition: all 0.1s linear;
                }
            }//&:hover
        }//.link--icon

        .link--icon:hover + .link-text {
            background: blue;
        }

        .link-holder:hover {
            text-decoration: none;

            .play-icon {
                width: 32px;
                height: 32px;
                transition: all 0.1s linear;
            }

            .link {
                cursor: pointer;
                border-color: transparent;
                transition: border-color linear;
                &:after {
                    border-width:1px;
                    animation:aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
                    animation-delay:0s, 0.25s, 0.5s;
                }
                &:before {
                    border-width:1px;
                    animation:nW_nH 0.5s, aW_nH 0.25s, sW_aH 0.25s, sW_sH 0.5s infinite;
                    animation-delay:0s, 0.5s, 0.75s, 1s;
                }
            }//.link
        }//.link-holder:hover
    }//.image-callout__link

    .image-callout__sub-text {
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 1.29;
        margin-top: 25px;

        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }

}//.image-callout

.middle-callout {

    &.image-callout .image-callout__image.order-2, &.image-callout .image-callout__image.order-1 {
        border-image: none;
        border-right: none;
        border-left: none;
    }

    @include media-breakpoint-down(md) {
        .image-callout__image {
            height: 320px;
            background: linear-gradient(to top, $light-grey 30%, $white 0);
            background-size: cover;
            background-position: top center;
            padding-left: 0;
        }
    }
}//.middle-callout

.middle__callout-text {

    @include media-breakpoint-up(lg) {
        position: absolute;
    }

    background-color: $light-grey;
    padding: 7%;

    @include media-breakpoint-down(md) {
        margin-top: -65px;
        height: 100%;
        margin-left: 8.3333% !important;
    }
}//.middle__callout-text

.image-callout.mobileOnDesktop {
        @include media-breakpoint-up(lg) {
            height: 100%;
            max-height: unset;

             .image-callout__image {
                height: 40vw;
                background-size: cover;
                background-position: top center;
                padding-left: 0;
                padding-right: 0;
                min-height: unset;

                img {
                    padding-right: 25px;
                    padding-left: 25px;
                }
            }

             .bluebox {
                    max-width: 1137px;
                }

            .middle__callout-text {
                text-align: center;
                max-width: 1577px;
                position: static;
                max-height: 412px;
                height: 21%;
                z-index: 999999;
                min-height: auto;
                margin: 0 auto !important;
                margin-top: -10% !important;
            }
        }// media up lg
    }//.image-callout.mobileOnDesktop



.image-callout .image-callout__image .callout-carousel .image__caption {
    padding: 26px 0;
}

.callout-carousel {
    .slick-controls {
        .slick-arrow {
            background: $white;
            height: unset;
            padding: 30px;
            top: unset;
            bottom: 36px;
            width: unset;
        }

        .slick-prev {
            right: 86px;
            left: unset;
        }

        .slick-next {
            right: 0;
        }

        .slick-prev:before, .slick-next:before {
            background-image: url(../theme-images/carrot.svg);
            background-size: 26px 26px;
            background-repeat: no-repeat;
            content: '';
            display: block;
            height: 26px;
            width: 26px;
        }

        .slick-prev:before {
            transform: rotate(90deg);
        }

        .slick-next:before {
            transform: rotate(-90deg);
        }
    }//.slick-controls

    .slick-active {
        position: relative;
    }
}//.callout-carousel

.image-callout__image.order-2 .callout-carousel .slick-controls .slick-arrow {
    &.slick-prev {
        right: unset;
    }

    &.slick-next {
        right: 0;
        left: 86px;
    }
}

.reversed.container-fluid,
.reversed .bluebox,
.reversed  img {
  transform: scaleX(-1);
}
  
.centered .col-lg-9 {
  max-width: 100%;
  flex: 0 0 100%;
  height: 40vw;
  max-height: 900px;
}

.centered .middle__callout-text {
  max-width: 1577px;
  min-width: 80%;
  position: static;
  max-height: 412px;
  height: 21%;
  padding: 7%;
  min-height: auto;
  margin: 0 auto !important;
  margin-top: -10% !important;
  z-index: 5;
}

.centered .col-lg-6 {
  max-width: 100%;
}

.centered .bluebox {
  max-width: 90%;
  text-align: center;
}

.centered img {
  object-fit: cover;
  object-position: top center;
}

.image-callout__image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}